<script setup>
    import { ref, onMounted, defineEmits } from 'vue';
    import { context } from 'o365-modules';
    import ContextSelector from 'o365.vue.components.ContextSelector.vue';

    const emits = defineEmits(['contextChanged', 'newContext']);

    const isLoaded = ref(false);

    const onContextChanged = (contextID) => {
        emits('contextChanged', contextID);
    }
    const onNewContext = (context) => {
        emits('newContext', context);
    }

    onMounted(async () => {
        isLoaded.value = true;
        await context.updateContextFromDB();
    });
</script>

<template>
    <ContextSelector v-if="isLoaded" @contextChanged="onContextChanged" @newContext="onNewContext"></ContextSelector>

    <div v-else class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</template>